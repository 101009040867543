<template>
  <div class="list-approval-config-page">
    <KTCodePreview v-bind:title="'Danh sách cấu hình duyệt chi'">
      <template v-slot:toolbar>
        <div class="row" v-if="checkPermission('APPROVAL_CONFIG_INSERT')">
          <router-link to="/approval-configs/info">
            <b-button
              v-bind:style="btnCreate"
              variant="primary"
              size="sm"
              class="font-weight-bolder"
            >
              <i style="font-size: 1rem" class="flaticon2-add-1"></i>Thêm mới
            </b-button>
          </router-link>
        </div>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <b-row class="mb-5">
          <b-col md="3">
            <b-form-input
              v-model="searchName"
              type="text"
              placeholder="Tên cấu hình"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <b-col md="3">
            <b-form-select
              size="sm"
              v-model="selectedType"
              :options="listType"
              value-field="id"
              text-field="name"
              placeholder="Tên cấu hình"
              class="select-style"
            ></b-form-select>
          </b-col>
          <b-col md="3">
            <b-form-select
              size="sm"
              v-model="selectedStatus"
              :options="listStatus"
              value-field="id"
              text-field="name"
              class="select-style"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col md="1">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter"
              >Lọc</b-button
            >
          </b-col>
        </b-row>
        <!-- End of Header session -->
        <!-- Table session -->
        <b-table
          :items="listItem"
          :fields="fields"
          bordered
          hover
          :busy="onLoading"
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
          </template>
          
          <template v-slot:cell(status)="row">
            <span
              :class="[
                'label', 
                'font-weight-bold', 
                'label-lg', 
                'label-inline', 
                row.item.status == 1 ? 'label-light-success' : 'label-light-danger'
              ]"
            >
              {{ getApprovalStatusName(row.item.status) }}
            </span>
          </template>
          <template v-slot:cell(createdByName)="row">
            <p class="text-center">{{ row.item.createdByName }}</p>
            <p class="text-center">{{ row.item.createdAt }}</p>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item
                  @click="editItem(row.item)"
                  v-if="!checkPermission('APPROVAL_CONFIG_UPDATE')"
                >
                  <span class="dropdown-item-text">
                    <i class="flaticon-eye"></i>
                    &nbsp; Chi tiết
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="editItem(row.item)"
                  v-if="checkPermission('APPROVAL_CONFIG_UPDATE')"
                >
                  <span class="dropdown-item-text">
                    <i class="flaticon2-pen"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDeleteAlert(row.item)"
                  v-if="checkPermission('APPROVAL_CONFIG_DELETE')"
                >
                  <span class="dropdown-item-text">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <!-- End of Table session -->

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng:
              {{ totalItems }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="custom-pagination"
              v-show="totalPages >= 2"
              :link-gen="linkGen"
              :number-of-pages="totalPages"
              use-router
              @change="fetchData"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { VclTable } from 'vue-content-loading';
import Swal from 'sweetalert2';
import {
  convertPrice,
  makeToastSuccess,
  cloneDeep,
} from '@/utils/common';
import { APPROVAL_TYPE, COMMON_STATUS_LIST, COMMON_STATUS_LIST_SELECT } from '@/utils/constants';
import { checkPermission } from '@/utils/saveDataToLocal';

export default {
  data() {
    return {
      btnCreate: {
        fontWeight: '600!important',
      },
      onLoading: false,
      listItem: [],
      totalPages: 1,
      totalItems: 0,
      page: 1,
      fields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-center',
        },
        {
          key: 'name',
          label: 'Tên cấu hình',
          thStyle: { fontWeight: 600, color: '#181c32', width: '30%' },
        },
        {
          key: 'type',
          label: 'Loại chi',
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
          formatter: (value) => this.getApprovalTypeName(value),
        },
        {
          key: 'status',
          label: 'Trạng thái',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
          tdClass: 'text-center',
        },
        {
          key: 'createdByName',
          label: 'Người tạo',
          thStyle: { fontWeight: 600, color: '#181c32', width: '25%' },
        },
        {
          key: 'actions',
          label: '',
        },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      limit: 10,
      selectedStatus: null,
      listStatus: COMMON_STATUS_LIST_SELECT,
      selectedType: null,
      listType: APPROVAL_TYPE,
      searchName: '',
    };
  },
  methods: {
    convertPrice,
    checkPermission,
    fetchData: async function () {
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }

      const params = {
        page: this.page,
        limit: 10,
        name: this.searchName ? this.searchName.trim() : '',
        status: this.selectedStatus,
        type: this.selectedType,
      };

      ApiService.query('approval-configs', { params })
        .then((response) => {
          const data = response.data.data;
          this.totalPages = data.paginations.totalPages;
          this.totalItems = data.paginations.total;
          this.listItem = data.data.map((item, index) => {
            return {
              ...item,
              count: index + 1,
            };
          });
          this.onLoading = false;
        })
        .catch((error) => {
          console.log('Errpor: ', error);
          this.onLoading = false;
        });
    },
    getApprovalTypeName(type) {
      const approvalType = APPROVAL_TYPE.find((item) => item.id === type);
      return approvalType ? approvalType.name : 'Không xác định';
    },
    getApprovalStatusName(status) {
      const approvalStatus = COMMON_STATUS_LIST.find((item) => item.id === status);
      return approvalStatus ? approvalStatus.name : 'Không xác định';
    },
    editItem: function (item) {
      this.$router.push({
        name: 'info-approval-config',
        query: { id: item.id },
      });
    },
    showDeleteAlert: function (item) {
      const title = 'Xóa cấu hình duyệt chi!';
      const text = 'Bạn có chắc muốn xóa cấu hình duyệt chi này?';
      const confirmText = 'Đồng ý';
      const optionsNotify = {
        title,
        text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: confirmText,
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        position: 'top',
      };

      Swal.fire(optionsNotify).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    deleteItem: async function (item) {
      ApiService.delete(`approval-configs/${item.id}`).then(({ data }) => {
        makeToastSuccess(data.message);
        this.fetchData();
      });
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-approval-config',
      });
      this.fetchData();
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Cấu hình duyệt chi', route: 'approval-configs' },
      { title: 'Danh sách cấu hình duyệt chi' },
    ]);
  },
  created() {
    this.listType = cloneDeep(APPROVAL_TYPE);
    this.listType.unshift({
      id: null,
      name: 'Chọn loại duyệt chi',
    });
    this.fetchData();
  },
  components: {
    KTCodePreview,
    VclTable,
  },
};
</script>

<style>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}

input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

select {
  height: 2.5em;
}

.example {
  border: 1px solid #ddd;
  margin-bottom: 2em;
}

code,
pre {
  margin: 1em 0;
  padding: 1em;
  border: 1px solid #bbb;
  display: block;
  background: #ddd;
  border-radius: 3px;
}

.settings {
  margin: 2em 0;
  border-top: 1px solid #bbb;
  background: #eee;
}

.form-group {
  margin-bottom: 1em;
}

.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}

.icon:hover {
  background-color: #90c6fc;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}
</style>
<style lang="scss">
.list-approval-config-page {
  .dropdown-item-text {
    font-size: 12px;
    color: #3f4254;
  }
  .dropdown-item {
    padding: 0px;
  }
}
</style>
